import { t } from "i18n-js";
import { noop } from "lodash";
import { ActionModal } from "@circle-react-shared/ModalsV2/ActionModal";
import { Form } from "@circle-react-uikit/Form";
import { ChargeInformation } from "./ChargeInformation";
import { RefundChargeForm } from "./RefundChargeForm";
import { formId } from "./constants";
import { useRefundChargeModal } from "./hooks/useRefundChargeModal";

const localeStringRoot = "settings.paywalls_admin_dashboard.charges";

export interface RefundChargeModalProps {
  charge: any;
  onSuccess: () => void;
}

export const RefundChargeModal = ({
  charge,
  onSuccess,
}: RefundChargeModalProps) => {
  const { defaultValues, handleSubmit, modal, isSubmitting, onClose } =
    useRefundChargeModal({
      charge,
      onSuccess,
    });

  return (
    <ActionModal
      title={t("settings.paywalls_admin_dashboard.charges.refund_modal.title")}
      isOpen={modal.visible}
      onClose={onClose}
      onAction={noop}
      disabled={isSubmitting}
      actionText={t(`${localeStringRoot}.refund_modal.refund_button_label`)}
      formId={formId}
    >
      <Form onSubmit={handleSubmit} defaultValues={defaultValues} id={formId}>
        <div className="pt-4">
          <ChargeInformation charge={charge} />
          <RefundChargeForm charge={charge} />
        </div>
      </Form>
    </ActionModal>
  );
};
