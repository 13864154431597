import { Footer } from "./Footer";
import { Header } from "./Header";
import { Layout as CustomizeUILayout } from "./Layout";
import { Main } from "./Main";
import { Sidebar } from "./Sidebar";

export const Layout = Object.assign(CustomizeUILayout, {
  Header,
  Footer,
  Main,
  Sidebar,
});
