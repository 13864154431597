import type { PropsWithChildren } from "react";
import { t } from "i18n-js";
import isFunction from "lodash/isFunction";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";

type ExitButtonProps = PropsWithChildren<{
  className?: string;
  onClick?: () => void;
}>;

export const ExitButton = ({
  className,
  onClick,
  children,
  ...rest
}: ExitButtonProps) => (
  <div className="flex items-center gap-2">
    <IconButton
      name="20-arrow-left"
      iconSize={20}
      iconClassName="!text-dark"
      className={className}
      dataTestId="exit"
      onClick={() => isFunction(onClick) && onClick()}
      ariaLabel={t("close")}
      {...rest}
    />
    {children}
  </div>
);
