import { useEffect, useMemo, useRef, useState } from "react";
import { t } from "i18n-js";
import { isEmpty } from "lodash";
import { VIEWPORT_WIDTHS } from "./constants";

interface ProgressiveImageProps {
  src: string;
  srcVariants: {
    thumbnail: string;
    small: string;
    medium: string;
    large: string;
  };
  alt?: string;
  className?: string;
}

interface NavigatorWithConnection extends Navigator {
  connection?: {
    effectiveType: string;
  };
}

export const ProgressiveImage = ({
  src,
  srcVariants,
  alt = t("post.cover_image_alt"),
  className = "",
}: ProgressiveImageProps) => {
  const [imageSrc, setImageSrc] = useState<string>(
    srcVariants?.thumbnail || src,
  );
  const imageRef = useRef<HTMLImageElement>(null);

  const isImageVariantsEmpty = isEmpty(srcVariants);

  const handleImageError = () => {
    setImageSrc(src);
  };

  const isNavigatorWithConnection = (
    navigator: Navigator,
  ): navigator is NavigatorWithConnection =>
    "connection" in navigator && typeof navigator.connection !== "undefined";

  const renderImageSrcBasedOnViewportWidthAndNetworkSpeed = (width: number) => {
    const connection = isNavigatorWithConnection(navigator)
      ? navigator.connection
      : undefined;
    const isSlow3gNetwork = connection && connection.effectiveType === "2g";
    if (isImageVariantsEmpty) return;

    if (isSlow3gNetwork) {
      setImageSrc(srcVariants.thumbnail);
    } else {
      if (width <= VIEWPORT_WIDTHS.small) {
        setImageSrc(srcVariants.small);
      } else if (width <= VIEWPORT_WIDTHS.medium) {
        setImageSrc(srcVariants.medium);
      } else {
        setImageSrc(srcVariants.large);
      }
    }
  };

  const handleImageInView = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const { width } = entry.boundingClientRect;
        renderImageSrcBasedOnViewportWidthAndNetworkSpeed(width);
      }
    });
  };

  const observer = useMemo(() => {
    const options = {
      threshold: 0.5,
    };
    return new IntersectionObserver(handleImageInView, options);
  }, []);

  useEffect(() => {
    if (imageRef.current) observer.observe(imageRef.current);
    () => {
      if (imageRef.current) observer.unobserve(imageRef.current);
    };
  }, [imageRef, observer]);

  if (isImageVariantsEmpty) {
    return <img loading="lazy" src={src} alt={alt} className={className} />;
  }

  return (
    <img
      loading="lazy"
      ref={imageRef}
      src={imageSrc || src}
      onError={handleImageError}
      alt={alt}
      className={className}
    />
  );
};
