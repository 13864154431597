import { useEffect } from "react";
import { noop } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { Component } from "./Component";
import "./styles.scss";

export const SpaceSelect = ({
  name = "",
  rules = {},
  defaultValue = null,
  onChange = noop,
  ...passThroughProps
}) => {
  const { control, setValue } = useFormContext();
  useEffect(() => {
    // Default value may take some time to load,
    // We're setting the value once it changes (becomes available)
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue]);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Component
          {...passThroughProps}
          name={field.name}
          value={field.value}
          onChange={value => {
            field.onChange(value);
            onChange?.();
            setValue(name, value, { shouldDirty: true, shouldTouch: true });
          }}
        />
      )}
    />
  );
};
