import classNames from "classnames";
import { t } from "i18n-js";
import { noop } from "lodash";
import type { IconType } from "@circle-react/components/shared/Icon/IconNames";
import { useCurrentUser } from "@circle-react/contexts";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { useBookmarks } from "./hooks/useBookmarks";

interface BookmarkButtonProps {
  bookmarkType: "post" | "event" | "lesson" | "comment" | "message";
  record: { id: number; [key: string]: any };
  variant?: "button" | "dropdown" | "minimal-post-modal";
  tooltip?: string;
  onSuccess?: () => void;
  onSettled?: () => void;
  className?: string;
}

interface IconButtonProperties {
  iconName: IconType;
  iconSize: number;
  iconClassSize: string;
  buttonSize: number;
}

export const BookmarkButton = ({
  bookmarkType,
  record,
  variant = "button",
  tooltip = record.bookmark_id
    ? t("bookmarks.actions.remove")
    : t(`bookmarks.actions.add_by_type.${bookmarkType}`),
  onSuccess,
  onSettled = noop,
  className = "",
}: BookmarkButtonProps) => {
  const currentUser = useCurrentUser();
  const bookmarkId = record.bookmark_id;
  const { createBookmark, deleteBookmark } = useBookmarks({
    bookmarkId,
    bookmarkType,
    record,
    onSuccess,
    onSettled,
    disableFetch: true,
  });

  const handleBookmarkToggle = () => {
    bookmarkId ? deleteBookmark() : createBookmark();
  };

  const allowedVariants = ["button", "dropdown", "minimal-post-modal"];
  const isUnsupportedVariantType = !allowedVariants.includes(variant);

  if (!currentUser || isUnsupportedVariantType) {
    return null;
  }

  const getIconButtonProperties = (
    bookmarkType: string,
    bookmarkId: number | null,
  ): IconButtonProperties => {
    if (bookmarkType === "lesson") {
      return {
        iconName: bookmarkId ? "16-bookmark-fill" : "16-bookmark",
        iconSize: 16,
        iconClassSize: "!w-4 !h-4",
        buttonSize: 24,
      };
    }
    if (bookmarkType === "message") {
      return {
        iconName: bookmarkId ? "16-bookmark-fill" : "16-bookmark",
        iconSize: 16,
        iconClassSize: classNames("!w-4 !h-4", {
          "!text-default": !bookmarkId,
        }),
        buttonSize: 24,
      };
    }

    return {
      iconName: bookmarkId ? "20-bookmark-fill" : "20-bookmark",
      iconSize: 20,
      iconClassSize: "!w-5 !h-5",
      buttonSize: 28,
    };
  };

  if (variant === "button") {
    const { iconName, iconSize, iconClassSize, buttonSize } =
      getIconButtonProperties(bookmarkType, bookmarkId);

    return (
      <TippyV2 content={tooltip} interactive={false}>
        <IconButton
          name={iconName}
          onClick={handleBookmarkToggle}
          iconSize={iconSize}
          buttonSize={buttonSize}
          className="group"
          variant="secondary"
          iconClassName={classNames(
            "text-dark",
            {
              "group-hover:text-darkest": !bookmarkId,
            },
            iconClassSize,
            className,
          )}
          ariaLabel={t("header_v3.bookmarks")}
          dataTestId="bookmark-button"
        />
      </TippyV2>
    );
  }

  if (variant === "dropdown") {
    return (
      <Dropdown.ItemLink onClick={handleBookmarkToggle}>
        {bookmarkId
          ? t("bookmarks.actions.remove")
          : t(`bookmarks.actions.add_by_type.${bookmarkType}`)}
      </Dropdown.ItemLink>
    );
  }

  if (variant === "minimal-post-modal") {
    return (
      <TippyV2 content={tooltip} interactive={false}>
        <IconButton
          name={bookmarkId ? "20-bookmark-fill" : "20-bookmark"}
          iconSize={20}
          iconClassName="!text-default group-hover:!text-dark"
          className="group"
          onClick={handleBookmarkToggle}
          buttonSize={24}
          ariaLabel={t("post.post_modal.bookmark_post")}
        />
      </TippyV2>
    );
  }

  return null;
};
