import classNames from "classnames";
import { t } from "i18n-js";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Icon } from "@circle-react-shared/Icon";
import type { ButtonProps } from "@circle-react-uikit/Button";
import { Button } from "@circle-react-uikit/Button";

export interface ModalCloseButtonProps extends Omit<ButtonProps, "type"> {
  onClick: () => void;
  className?: string;
  isVisible?: boolean;
}

export const ModalCloseButton = ({
  onClick,
  className = "",
  isVisible = true,
  ...rest
}: ModalCloseButtonProps) => {
  const isSmScreen = useSmScreenMediaQuery();

  if (!isVisible) return null;

  return (
    <Button
      type="button"
      variant="plain"
      small
      onClick={onClick}
      className={classNames(
        {
          "fixed right-5 top-6 md:right-2 md:top-2 lg:right-4 lg:top-1":
            !isSmScreen,
        },
        className,
      )}
      {...rest}
    >
      <span className="sr-only">{t("close")}</span>
      <Icon
        className={classNames({
          "w-[1em] text-gray-100": !isSmScreen,
          "!text-dark": isSmScreen,
        })}
        type={isSmScreen ? "24-close" : "20-close"}
        size={isSmScreen ? 24 : 20}
      />
    </Button>
  );
};
