import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";

export const MemberCount = ({ count }) => {
  if (!count) return null;
  const memberCountLabel =
    count === 1 ? "member_count_singular" : "member_count_plural";

  return (
    <Typography.LabelXxs>
      {t(`community_member_select.${memberCountLabel}`, { count })}
    </Typography.LabelXxs>
  );
};

MemberCount.propTypes = {
  count: PropTypes.number,
};
