import { t } from "i18n-js";
import { useMutation } from "react-query";
import {
  reactQueryDelete,
  reactQueryGet,
  reactQueryPost,
  reactQueryPut,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { IndexParams } from "@circle-react/helpers/urlHelpers/internalApi/scheduledEmails";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useScheduledEmailsForm = () => {
  const toast = useToast();

  const { mutateAsync: createScheduledEmail, isLoading: isCreateLoading } =
    useMutation(
      (params: Record<string, any>) =>
        reactQueryPost(internalApi.scheduledEmails.create({}), params),
      {
        onSuccess: () => {
          toast.success(t("changes_saved"));
        },
        onError: () => {
          toast.error(t("request_failure_message"));
        },
      },
    );

  const { mutateAsync: updateScheduledEmail, isLoading: isUpdateLoading } =
    useMutation(
      (params: Record<string, any>) =>
        reactQueryPut(
          internalApi.scheduledEmails.update({ id: params.id }),
          params,
        ),
      {
        onSuccess: () => {
          toast.success(t("changes_saved"));
        },
        onError: () => {
          toast.error(t("request_failure_message"));
        },
      },
    );

  const saveScheduledEmail = async (params: Record<string, any>) => {
    if (params?.id) {
      await updateScheduledEmail(params);
    } else {
      await createScheduledEmail(params);
    }
  };

  const { mutateAsync: destroyScheduledEmail, isLoading: isDestroyLoading } =
    useMutation(
      (params: Record<string, any>) =>
        reactQueryDelete(
          internalApi.scheduledEmails.destroy({ id: params.id }),
          params,
        ),
      {
        onSuccess: () => {
          toast.success(t("scheduled_emails.delete.record_deleted"));
        },
        onError: () => {
          toast.error(t("request_failure_message"));
        },
      },
    );

  const getScheduledEmailList = (params: IndexParams) =>
    reactQueryGet(internalApi.scheduledEmails.index(params));

  return {
    createScheduledEmail,
    getScheduledEmailList,
    updateScheduledEmail,
    destroyScheduledEmail,
    isLoading: isCreateLoading || isUpdateLoading,
    isDestroyLoading,
    saveScheduledEmail,
  };
};
