import { useRef } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Search } from "../Search";
import { SelectedRecords } from "../SelectedRecords";

export const Selection = ({ searchPlaceholder, disableSearch }) => {
  const selectionModalRef = useRef(null);

  return (
    <div ref={selectionModalRef} className="flex max-h-96 flex-col">
      <Search
        placeholder={
          searchPlaceholder || t("community_member_select.search_placeholder")
        }
        selectionModalRef={selectionModalRef}
        disableSearch={disableSearch}
      />
      <SelectedRecords />
    </div>
  );
};

Selection.propTypes = {
  searchPlaceholder: PropTypes.string,
  disableSearch: PropTypes.bool,
};
