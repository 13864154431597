import { t } from "i18n-js";
import { Loader } from "@circle-react-uikit/Loader";
import { useCommunityMemberPickerContext } from "../CommunityMemberPickerContext";
import { RecordTypeLabel } from "../RecordTypeLabel";
import { CommunityMemberRecord } from "./CommunityMemberRecord";
import { MemberTagRecord } from "./MemberTagRecord";
import { SpaceGroupRecord } from "./SpaceGroupRecord";
import { SpaceRecord } from "./SpaceRecord";

const hasRecords = (
  spaceGroups = [],
  spaces = [],
  memberTags = [],
  communityMembers = [],
) =>
  spaceGroups.length ||
  spaces.length ||
  memberTags.length ||
  communityMembers.length;

export const SelectedRecords = () => {
  const {
    selectionState = {},
    onRemove,
    includeAllResultTypes: displayLabels = false,
    isLoading,
  } = useCommunityMemberPickerContext();
  const { spaceGroups, spaces, memberTags, communityMembers } = selectionState;

  if (isLoading) {
    return <Loader />;
  }
  if (!hasRecords(spaceGroups, spaces, memberTags, communityMembers)) {
    return null;
  }

  return (
    <div className="mt-3 grow-0 overflow-auto">
      {displayLabels && !!spaceGroups?.length && (
        <RecordTypeLabel className="pl-0">
          {t("community_member_select.record_type_labels.space_groups")}
        </RecordTypeLabel>
      )}
      {spaceGroups?.map(record => (
        <SpaceGroupRecord key={record.id} record={record} onClick={onRemove} />
      ))}
      {displayLabels && !!spaces?.length && (
        <RecordTypeLabel className="pl-0">
          {t("community_member_select.record_type_labels.spaces")}
        </RecordTypeLabel>
      )}
      {spaces?.map(record => (
        <SpaceRecord key={record.id} record={record} onClick={onRemove} />
      ))}
      {displayLabels && !!memberTags?.length && (
        <RecordTypeLabel className="pl-0">
          {t("community_member_select.record_type_labels.member_tags")}
        </RecordTypeLabel>
      )}
      {memberTags?.map(record => (
        <MemberTagRecord key={record.id} record={record} onClick={onRemove} />
      ))}
      {displayLabels && !!communityMembers?.length && (
        <RecordTypeLabel className="pl-0">
          {t("community_member_select.record_type_labels.members")}
        </RecordTypeLabel>
      )}
      {communityMembers?.map(record => (
        <CommunityMemberRecord
          key={record.id}
          record={record}
          onClick={onRemove}
        />
      ))}
    </div>
  );
};
