import { useState } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import { isFunction } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { Button } from "@circle-react-uikit/Button";
import { CommunityMemberPickerProvider } from "./CommunityMemberPickerContext";
import { Selection } from "./Selection";
import { SelectionModal } from "./SelectionModal";
import { Summary } from "./Summary";

export const VARIANTS = {
  SIMPLE: "simple",
  MODAL: "modal",
  BUTTON: "button",
};

export const CommunityMemberPickerComponent = ({
  onChange,
  value,
  name,
  countLabel,
  tooltipText,
  selectionLabel,
  searchPlaceholder,
  buttonLabel,
  modalSaveButtonLabel,
  includeAllResultTypes = false,
  renderAfterSelection = null,
  variant = VARIANTS.SIMPLE,
  disableSearch,
  excludedMemberIds = [],
  allowCurrentCommunityMember = false,
  onModalClose,
  onModalSave,
  ...passThroughProps
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
    if (isOpen && isFunction(onModalClose)) {
      onModalClose();
    }
  };

  return (
    <CommunityMemberPickerProvider
      onChange={onChange}
      value={value}
      name={name}
      includeAllResultTypes={includeAllResultTypes}
      excludedMemberIds={excludedMemberIds}
      allowCurrentCommunityMember={allowCurrentCommunityMember}
      variant={variant}
      onModalSave={onModalSave}
    >
      {variant === VARIANTS.SIMPLE && (
        <Selection
          searchPlaceholder={searchPlaceholder}
          disableSearch={disableSearch}
        />
      )}
      {variant === VARIANTS.MODAL && (
        <>
          <Summary
            buttonLabel={buttonLabel}
            countLabel={countLabel}
            tooltipText={tooltipText}
            onClick={toggleModal}
            {...passThroughProps}
          />
          <SelectionModal
            isOpen={isOpen}
            onClose={toggleModal}
            selectionLabel={selectionLabel}
            searchPlaceholder={searchPlaceholder}
            modalSaveButtonLabel={modalSaveButtonLabel}
            renderAfterSelection={renderAfterSelection}
            disableSearch={disableSearch}
          />
        </>
      )}
      {variant === VARIANTS.BUTTON && (
        <>
          <Button
            type="button"
            variant="secondary"
            onClick={toggleModal}
            className="min-w-[96px]"
          >
            {buttonLabel || t("add")}
          </Button>
          <SelectionModal
            isOpen={isOpen}
            onClose={toggleModal}
            selectionLabel={selectionLabel}
            searchPlaceholder={searchPlaceholder}
            modalSaveButtonLabel={modalSaveButtonLabel}
            renderAfterSelection={renderAfterSelection}
            disableSearch={disableSearch}
          />
        </>
      )}
    </CommunityMemberPickerProvider>
  );
};

CommunityMemberPickerComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  name: PropTypes.string,
  countLabel: PropTypes.string,
  tooltipText: PropTypes.string,
  selectionLabel: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  buttonLabel: PropTypes.string,
  modalSaveButtonLabel: PropTypes.string,
  includeAllResultTypes: PropTypes.bool,
  renderAfterSelection: PropTypes.node,
  disableSearch: PropTypes.bool,
  excludedMemberIds: PropTypes.array,
  allowCurrentCommunityMember: PropTypes.bool,
  onModalClose: PropTypes.func,
  onModalSave: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
};

export const CommunityMemberPicker = ({ name, rules, ...passThroughProps }) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <CommunityMemberPickerComponent
          {...passThroughProps}
          onChange={onChange}
          value={value}
          name={name}
        />
      )}
    />
  );
};

CommunityMemberPicker.propTypes = {
  name: PropTypes.string,
  rules: PropTypes.object,
  countLabel: PropTypes.string,
  tooltipText: PropTypes.string,
  selectionLabel: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  buttonLabel: PropTypes.string,
  modalSaveButtonLabel: PropTypes.string,
  includeAllResultTypes: PropTypes.bool,
  renderAfterSelection: PropTypes.node,
  disableSearch: PropTypes.bool,
  excludedMemberIds: PropTypes.array,
  allowCurrentCommunityMember: PropTypes.bool,
  onModalClose: PropTypes.func,
  onModalSave: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
};
