import { Body } from "./components/Body";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { HeaderContent } from "./components/HeaderContent";
import { HeaderNav } from "./components/HeaderNav";
import { Modal } from "./components/Modal";

const FullScreenModal = Object.assign(Modal, {
  Header,
  HeaderContent,
  HeaderNav,
  Body,
  Footer,
});

export { FullScreenModal };
