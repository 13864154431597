import { ScreenSizeSelect } from "@circle-react-shared/ScreenSizeSelect";
import { ExitButton } from "./ExitButton";
import { Header as CustomizeUIHeader } from "./Header";
import { HeaderActions } from "./HeaderActions";
import { LinkButton } from "./LinkButton";
import { SaveButton } from "./SaveButton";

const Header = CustomizeUIHeader;
Header.Actions = HeaderActions;
Header.ExitButton = ExitButton;
Header.LinkButton = LinkButton;
Header.SaveButton = SaveButton;
Header.ScreenSizeSelect = ScreenSizeSelect;

export { Header };
