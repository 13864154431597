import { t } from "i18n-js";
import type { Record } from "@circle-react-shared/CommunityMemberPicker/interfaces";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";
import { MemberCount } from "../../MemberCount";

export interface SpaceGroupRecordProps {
  record: Record;
  onClick?: (record: Record) => void;
}

export const SpaceGroupRecord = ({
  record,
  onClick,
}: SpaceGroupRecordProps) => {
  const { name, active_space_group_members_count: count } = record;

  const handleClick = () => {
    onClick?.(record);
  };

  return (
    <button
      type="button"
      className="group flex w-full cursor-pointer items-baseline gap-3 p-2 text-left"
      onClick={handleClick}
    >
      <Icon size={16} type="bookmark-folder" />
      <Typography.LabelSm>{name}</Typography.LabelSm>
      <div className="min-w-[6rem] grow">
        <MemberCount count={count} />
      </div>
      <div className="text-dark invisible group-hover:visible">
        <Typography.LabelSm>{t("remove")}</Typography.LabelSm>
      </div>
    </button>
  );
};
