import { t } from "i18n-js";
import { useMutation } from "react-query";
import { paywallChargesApi } from "@/react/api/paywallChargesApi";
import { useToast } from "@circle-react-uikit/ToastV2";

const { refundCharge } = paywallChargesApi;

export const usePaywallRefundCharge = ({ charge, callback }) => {
  const maximumAmountToRefund = charge.amount_to_refund_raw;
  const { success, error } = useToast();
  const defaultErrorMessage = t(
    "settings.paywalls_admin_dashboard.charges.refund_modal.alerts.error.default_message",
  );

  const refundMutation = useMutation(
    params =>
      refundCharge({
        chargeProcessorId: charge.processor_id,
        params,
      }),
    {
      onSuccess: (_, requestPayload) => {
        const successMessage =
          requestPayload.amount === maximumAmountToRefund
            ? t(
                "settings.paywalls_admin_dashboard.charges.refund_modal.alerts.success.refund_succeed",
              )
            : t(
                "settings.paywalls_admin_dashboard.charges.refund_modal.alerts.success.partial_refund_succeed",
              );

        callback.refundCharge.onSuccess();
        success(successMessage);
      },
      onError: updateError => {
        callback.refundCharge.onError();
        const errorMessage = updateError?.message ?? defaultErrorMessage;
        console.error(errorMessage);
        error(errorMessage, { duration: "long" });
      },
    },
  );

  return {
    refundMutation,
  };
};
