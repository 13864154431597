import invariant from "tiny-invariant";
import { t } from "@circle-react/custom_i18n";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Typography } from "@circle-react-uikit/Typography";

interface LabelTitleProps {
  children: string;
}

export function LabelTitle({ children }: LabelTitleProps) {
  invariant(typeof children === "string", "children must be a string");

  const labelTooltipText = t([
    "settings.community_ai.activity_scores",
    children.toLowerCase(),
    "tooltip",
  ]);
  return (
    <div className="text-default flex items-center gap-x-0.5">
      <Typography.BodySm color="text-default" weight="font-semibold">
        {children}
      </Typography.BodySm>
      {labelTooltipText && (
        <TippyV2 content={labelTooltipText}>
          <Icon
            type="16-info"
            useWithStrokeCurrentColor
            size={16}
            className="h-3 w-3"
          />
        </TippyV2>
      )}
    </div>
  );
}
