import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { useMdScreenMediaQuery } from "../../../../hooks/useMediaQuery";
import { useEditorApi } from "../TrixEditorContext";
import { useEditorMode } from "../useEditorMode";

export const BlockSelectorBtn = () => {
  const {
    setModeModalBlockSelector,
    setModeInlineBlockSelector,
    showInlineBlockSelector,
  } = useEditorMode();
  const { setSelectedRange, getPosition } = useEditorApi();
  const isMdScreen = useMdScreenMediaQuery();

  const onClick = () => {
    if (!isMdScreen) {
      setModeModalBlockSelector();
    } else if (!showInlineBlockSelector()) {
      const position = getPosition();
      setSelectedRange([position, position]);
      setModeInlineBlockSelector({ withPrefix: false });
    }
  };

  return (
    <button
      type="button"
      className="toolbar__btn"
      onClick={onClick}
      aria-label={t("trix_editor.actions.add_a_block_of_code")}
    >
      <Icon type="block-menu-btn" size={20} />
    </button>
  );
};
