import { useMemo } from "react";
import { UserImageStatus as UIKitUserImageStatus } from "@circleco/ui-kit";
import type { UserImageStatusProps as UIKitUserImageStatusProps } from "@circleco/ui-kit";
import { omit } from "lodash";
import { useFrame } from "react-frame-component";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

const useGetPropertyValue = (property: string) => {
  const { document } = useFrame();
  return useMemo(() => {
    if (!document) return "";
    const { documentElement } = document;
    return getComputedStyle(documentElement).getPropertyValue(property);
  }, [document, property]);
};

export interface UserImageStatusProps
  extends Omit<UIKitUserImageStatusProps, "onlineColor" | "idleColor"> {
  onlineColor: never;
  idleColor: never;
}

export const UserImageStatus = (props: UIKitUserImageStatusProps) => {
  const customOnlineColor = useGetPropertyValue("--color-online-background");
  const rest = omit(props, ["onlineColor", "idleColor"]);
  const { isV3Enabled } = useIsV3();

  return (
    <UIKitUserImageStatus
      {...rest}
      onlineColor={customOnlineColor}
      isFullRounded={isV3Enabled}
    />
  );
};
