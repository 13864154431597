import { t } from "i18n-js";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";
import type { Record } from "../../interfaces";

export interface CommunityMemberRecordProps {
  record: Record;
  onClick?: (record: Record) => void;
}

export const CommunityMemberRecord = ({
  record,
  onClick,
}: CommunityMemberRecordProps) => {
  const { name, avatar_url: avatarUrl } = record;

  const handleClick = () => {
    onClick?.(record);
  };

  return (
    <button
      type="button"
      className="group flex w-full cursor-pointer items-center gap-3 p-2 text-left"
      onClick={handleClick}
    >
      <div>
        <UserImage name={name} src={avatarUrl} size="8" />
      </div>
      <div className="min-w-[6rem] grow">
        <Typography.LabelSm>{name}</Typography.LabelSm>
      </div>
      <div className="text-darkest invisible group-hover:visible">
        <Typography.LabelSm>{t("remove")}</Typography.LabelSm>
      </div>
    </button>
  );
};
