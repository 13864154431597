import { t } from "i18n-js";
import { flatten, isFunction } from "lodash";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import {
  reactQueryDelete,
  reactQueryGet,
  reactQueryPost,
} from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import type { BookmarkType } from "@circle-react/types/Bookmarks";
import type { BookmarksPaginationResponse } from "@circle-react/types/Bookmarks";
import { useToast } from "@circle-react-uikit/ToastV2";

interface UseBookmarksProps {
  bookmarkId?: number | null;
  bookmarkType?: BookmarkType;
  record?: { id: number; [key: string]: any };
  onSuccess?: (bookmarkId: number | null) => void;
  onSettled?: () => void;
  disableFetch?: boolean;
}

interface FetchProps {
  pageParam?: number;
}

export const useBookmarks = ({
  bookmarkId,
  bookmarkType,
  record,
  onSuccess,
  onSettled,
  disableFetch = false,
}: UseBookmarksProps) => {
  const { currentCommunityMember } = usePunditUserContext();
  const toastr = useToast();
  const queryClient = useQueryClient();

  const queryKey = ["bookmark", bookmarkType];

  const fetchBookmarks = (page: number): Promise<BookmarksPaginationResponse> =>
    reactQueryGet(
      internalApi.bookmarks.index({
        params: {
          page,
          bookmark_type: bookmarkType,
        },
      }),
    );

  const resetQuery = async () => {
    await queryClient.invalidateQueries(queryKey);
  };

  const { mutate: createBookmark, isLoading: isCreatingBookmark } = useMutation(
    () =>
      reactQueryPost(internalApi.bookmarks.create(), {
        bookmark: { bookmark_type: bookmarkType, record_id: record?.id },
      }),
    {
      onSuccess: data => {
        if (isFunction(onSuccess)) {
          onSuccess(data.id);
        }
        toastr.success(t("bookmarks.saved_to_bookmarks"));
      },

      onError: () => {
        toastr.error(t("bookmarks.errors.create_failure"));
      },

      onSettled: onSettled,
    },
  );

  const { mutate: deleteBookmark, isLoading: isDeletingBookmark } = useMutation(
    () =>
      reactQueryDelete(internalApi.bookmarks.destroy({ id: bookmarkId }), {
        bookmark_type: bookmarkType,
      }),
    {
      onSuccess: () => {
        if (isFunction(onSuccess)) {
          onSuccess(null);
        }
        toastr.success(t("bookmarks.removed_from_bookmarks"), {
          duration: "short",
          shouldUseProgress: false,
        });
      },

      onError: () => {
        toastr.error(t("bookmarks.errors.delete_failure"));
      },

      onSettled: onSettled,
    },
  );

  const { data, error, isLoading, fetchNextPage, hasNextPage } =
    useInfiniteQuery<BookmarksPaginationResponse, Error>(
      queryKey,
      ({ pageParam = 1 }: FetchProps) => fetchBookmarks(pageParam),
      {
        enabled: !!currentCommunityMember,
        staleTime: disableFetch ? Infinity : 0,
        getNextPageParam: lastPage =>
          lastPage.has_next_page ? lastPage.page + 1 : undefined,
        onError: () => {
          toastr.error(t("bookmarks.errors.fetch_failure"));
        },
      },
    );

  const getBookmarks = () =>
    flatten(data?.pages?.map(page => page.records) || []);

  return {
    createBookmark,
    isCreatingBookmark,
    deleteBookmark,
    isDeletingBookmark,
    getBookmarks,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    resetQuery,
  };
};
