import PropTypes from "prop-types";
import classNames from "classnames";

export const HeaderActions = ({ children, className, ...rest }) => (
  <div className={classNames("flex items-center gap-6", className)} {...rest}>
    {children}
  </div>
);

HeaderActions.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
