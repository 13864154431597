import { t } from "i18n-js";
import { Icon } from "../../Icon";
import { useEditorMode } from "../useEditorMode";

export interface MediaUploaderProps {
  attribute: string;
  subtype: "image" | "video";
}

export const MediaUploader = ({ attribute, subtype }: MediaUploaderProps) => {
  const { setModeInlineModalSelector, setSubTypeInlineModalSelector } =
    useEditorMode();

  const onClick = () => {
    setModeInlineModalSelector(attribute);
    setSubTypeInlineModalSelector(subtype);
  };

  return (
    <>
      {subtype === "image" && (
        <button
          type="button"
          aria-label={t("trix_editor.toolbar.image")}
          className="toolbar__btn"
          onClick={onClick}
        >
          <Icon type="toolbar-image" size={20} />
        </button>
      )}

      {subtype === "video" && (
        <button
          type="button"
          aria-label={t("trix_editor.toolbar.video")}
          className="toolbar__btn"
          onClick={onClick}
        >
          <Icon type="toolbar-video" size={20} />
        </button>
      )}
    </>
  );
};
