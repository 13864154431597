import { useRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { t } from "i18n-js";
import { Modal } from "@circle-react-uikit/Modal";
import { useFocusOnLoad } from "../../../../hooks/useFocusOnLoad";
import { useSmScreenMediaQuery } from "../../../../hooks/useMediaQuery";
import { VirtualAnchorPopup } from "../../VirtualAnchorPopup";
import { useEditorApi } from "../TrixEditorContext";
import { useEditorMode } from "../useEditorMode";
import "./styles.scss";

export const InlineLinkForm = () => {
  const {
    toggleOffInlineLinkForm,
    showInlineLinkForm,
    startPositionRect,
    endPositionRect,
    resetEditorMode,
  } = useEditorMode();

  const { trixApiWrapper } = useEditorApi();
  const defaultValue = trixApiWrapper.getHrefAtCurrentPosition() || "";
  const [href, setHref] = useState(defaultValue);
  const isVisible = showInlineLinkForm();
  const active = Boolean(trixApiWrapper.attributeIsActive("href"));
  const inputRef = useFocusOnLoad([isVisible]);
  const divRef = useRef(null);
  const smScreen = useSmScreenMediaQuery();

  if (!isVisible) {
    return null;
  }

  return (
    <ContainerComponent
      smScreen={smScreen}
      startPositionRect={startPositionRect}
      endPositionRect={endPositionRect}
      resetEditorMode={resetEditorMode}
    >
      <div
        ref={divRef}
        className={classnames("link-form", { "link-form--active": active })}
      >
        <form
          onSubmit={event => {
            event.preventDefault();
          }}
        >
          <input
            ref={inputRef}
            className="form-control placeholder:text-light placeholder:opacity-100"
            type="text"
            placeholder={t("paste_link")}
            value={href}
            onChange={event => setHref(event.target.value)}
          />
          {!active && (
            <button
              type="submit"
              className="btn btn-primary btn-link-toolbar"
              onClick={event => {
                event.preventDefault();
                if (href) {
                  let url = href;
                  toggleOffInlineLinkForm(false);
                  trixApiWrapper.recordUndoEntry();
                  const isValidUrl = url.match(/^(https?:\/\/|mailto:)/i);
                  if (!isValidUrl) {
                    url = "http://" + url;
                  }
                  trixApiWrapper.activateAttribute("href", url);
                  setHref("");
                }
              }}
            >
              {t("link")}
            </button>
          )}
          {active && (
            <button
              type="submit"
              className="btn btn-primary btn-link-toolbar"
              onClick={event => {
                event.preventDefault();
                if (href) {
                  toggleOffInlineLinkForm(true);
                  trixApiWrapper.recordUndoEntry();
                  trixApiWrapper.deactivateAttribute("href", href);
                }
              }}
            >
              {t("unlink")}
            </button>
          )}
        </form>
      </div>
    </ContainerComponent>
  );
};

const ContainerComponent = ({
  children,
  smScreen,
  resetEditorMode,
  endPositionRect,
  startPositionRect,
}) => {
  if (smScreen) {
    return (
      <Modal
        variant="large"
        show
        title={t("add_a_link")}
        onClose={resetEditorMode}
      >
        {children}
      </Modal>
    );
  }
  const { top, left } = startPositionRect() || {};
  const { bottom, right } = endPositionRect() || {};
  return (
    <div className="link-form__wrap">
      <VirtualAnchorPopup show anchorRect={{ top, bottom, left, right }}>
        {children}
      </VirtualAnchorPopup>
    </div>
  );
};

ContainerComponent.propTypes = {
  children: PropTypes.node,
  smScreen: PropTypes.bool,
  resetEditorMode: PropTypes.func,
  endPositionRect: PropTypes.func,
  startPositionRect: PropTypes.func,
};
