import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { useCommunityMemberPickerContext } from "../CommunityMemberPickerContext";
import { Selection } from "../Selection";

export const SelectionModal = ({
  isOpen,
  onClose,
  selectionLabel,
  searchPlaceholder,
  modalSaveButtonLabel,
  renderAfterSelection = null,
  disableSearch,
}) => {
  const { onSave } = useCommunityMemberPickerContext();
  const handleOnSave = () => {
    onSave();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Overlay />
      <Modal.Content className="overflow-visible">
        <Modal.Header>
          <Modal.Title className="text-center">
            {selectionLabel || t("community_member_select.selection_label")}
          </Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={onClose} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="overflow-y-visible py-4">
          <Selection
            searchPlaceholder={searchPlaceholder}
            disableSearch={disableSearch}
          />
        </Modal.Body>
        <Modal.Footer>
          {renderAfterSelection}
          <Button
            type="submit"
            variant="primary"
            full
            large
            onClick={handleOnSave}
          >
            {modalSaveButtonLabel || t("save")}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

SelectionModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  selectionLabel: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  modalSaveButtonLabel: PropTypes.string,
  renderAfterSelection: PropTypes.node,
  disableSearch: PropTypes.bool,
};
