import { useEffect, useState } from "react";
import { t } from "i18n-js";
import InfiniteScroll from "react-infinite-scroll-component";
import * as giphyFetch from "../../../../helpers/giphyFetchHelpers";
import { useFocusOnLoad } from "../../../../hooks/useFocusOnLoad";
import { useMdScreenMediaQuery } from "../../../../hooks/useMediaQuery";
import { Icon } from "../../Icon";
import { useEditorApi } from "../TrixEditorContext";
import { useDisableWindowScroll } from "../useDisableWindowScroll";
import { Gif } from "./Gif";
import Logo from "./giphy.png";

export const GiphySearchGrid = ({ show, onClose }) => {
  const [gifs, setGifs] = useState([]);
  const [search, setSearch] = useState("");
  const mdScreen = useMdScreenMediaQuery();

  const fetchGifs = async (offset = null) => {
    if (offset === null) {
      offset = gifs.length * 10;
    }
    let data = [];
    if (search) {
      data = (await giphyFetch.search(search, { offset: offset, limit: 10 }))
        .data;
    } else {
      data = (await giphyFetch.trending({ offset: offset, limit: 10 })).data;
    }
    setGifs([...gifs, ...data]);
  };

  const { trixApiWrapper } = useEditorApi();

  const onClick = async (gif, event) => {
    event.preventDefault();
    onClose();
    const response = await fetch(gif.images.original.url);
    let extention = ".gif";
    let content_type = "image/gif";
    if (gif.type === "video") {
      extention = ".mp4";
      content_type = "video/mp4";
    }
    const blob = await response.blob();
    const file = new File([blob], `${gif.id}${extention}`, {
      type: content_type,
    });
    trixApiWrapper.insertFile(file);
  };
  const inputRef = useFocusOnLoad([show]);
  useEffect(() => {
    if (!gifs.length) {
      fetchGifs();
    }
  }, [gifs]);

  useDisableWindowScroll(show && mdScreen);

  if (!show) {
    return null;
  }

  const onSearch = event => {
    setSearch(event.target.value);
    setGifs([]);
  };

  return (
    <div className="inline-toolbar">
      <div>
        <input
          ref={inputRef}
          type="text"
          placeholder={t("trix_editor.toolbar.search_giphy")}
          onChange={onSearch}
          value={search}
          style={{ width: "100%" }}
          className="giphy-input placeholder:text-light placeholder:opacity-100"
        />

        <InfiniteScroll
          dataLength={gifs.length}
          next={fetchGifs}
          hasMore
          loader={<Icon type="loader" />}
          height={300}
          style={{ width: 300 }}
          className="giphy-grid"
        >
          {gifs.map(gif => (
            <Gif
              hideAttribution
              noLink={false}
              key={gif.id}
              gif={gif}
              width={150}
              onGifClick={onClick}
            />
          ))}
        </InfiniteScroll>
        <img loading="lazy" src={Logo} className="giphy-logo" alt="" />
      </div>
    </div>
  );
};
