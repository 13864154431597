import PropTypes from "prop-types";
import { t } from "i18n-js";
import { noop } from "lodash";
import {
  GroupedOptions,
  RegularOptions,
} from "@circle-react-shared/TextSelect";
import {
  SearchableSelectDropdown,
  filterByLabel,
  useSearchableSelect,
} from "@circle-react-uikit/SearchableSelect";

/**
 * @typedef {Object} TextSelectDropdownOption
 * @property {string} label
 * @property {string} value
 * @property {string=} description
 * @property {string=} group
 */

/**
 *
 * @param {Object} props
 * @param {Array} props.options
 * @param {(option: TextSelectDropdownOption) => string | undefined} props.groupBy
 * @returns
 */
export const TextSelectDropdown = ({
  options = [],
  onSelect = noop,
  placeholder = t("select_an_option"),
  emptyMessage = t("no_options"),
  filterBy = filterByLabel,
  groupBy = null,
  displayValue = option => option?.label || "",
  children,
  buttonRef,
  optionsClassName,
}) => {
  const {
    dropdownProps,
    searchResults,
    hasNoResults,
    groupedSearchResults,
    hasGroups,
  } = useSearchableSelect({
    options,
    onChange: onSelect,
    filterBy,
    placeholder,
    optionsClassName,
    displayValue,
    groupByValue: groupBy,
  });

  return (
    <SearchableSelectDropdown
      {...dropdownProps}
      buttonRef={buttonRef}
      button={children}
    >
      {hasNoResults && (
        <SearchableSelectDropdown.EmptyOption label={emptyMessage} />
      )}
      {!hasNoResults && !hasGroups && (
        <RegularOptions options={searchResults} />
      )}
      {!hasNoResults && hasGroups && (
        <GroupedOptions groupedOptions={groupedSearchResults} />
      )}
    </SearchableSelectDropdown>
  );
};

TextSelectDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  emptyMessage: PropTypes.string,
  filterBy: PropTypes.func,
  displayValue: PropTypes.func,
  groupBy: PropTypes.func,
  children: PropTypes.node,
  buttonRef: PropTypes.object,
  optionsClassName: PropTypes.string,
};
