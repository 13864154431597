import PropTypes from "prop-types";
import classnames from "classnames";
import { t } from "i18n-js";
import { find } from "lodash";
import { useDropdown } from "../DropdownMenu";
import { SpaceSelectButton } from "./SpaceSelectButton";
import { SpacesDropdown } from "./SpacesDropdown";

export const Component = ({
  spaces,
  onChange,
  value,
  variant,
  containerClassname,
  dropdownClassName,
  shouldShowEmoji,
  defaultButtonLabel = t("select_space"),
  placeholder = t("search_space_placeholder"),
  customButton,
  shouldDisplayHomepage,
}) => {
  const { open, toggleDropdown, handleClose } = useDropdown(false);
  const selectedSpace = find(spaces, { id: value });
  const containerClassnames = classnames(
    "react-space-selector",
    {
      "form-control": variant === "form-input",
      "react-space-selector--open": open,
    },
    containerClassname,
  );

  const SpaceSelectButtonComponent = customButton || SpaceSelectButton;

  return (
    <div className={containerClassnames} data-testid="space-selector">
      <SpaceSelectButtonComponent
        selectedSpace={selectedSpace}
        toggleDropdown={toggleDropdown}
        shouldShowEmoji={shouldShowEmoji}
        defaultLabel={defaultButtonLabel}
        variant={variant}
        shouldDisplayHomepage={shouldDisplayHomepage}
        value={value}
      />
      <SpacesDropdown
        placeholder={placeholder}
        handleClose={handleClose}
        open={open}
        onChange={onChange}
        spaces={spaces}
        className={dropdownClassName}
        shouldDisplayHomepage={shouldDisplayHomepage}
      />
    </div>
  );
};

Component.propTypes = {
  spaces: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  variant: PropTypes.string,
  containerClassname: PropTypes.string,
  dropdownClassName: PropTypes.string,
  shouldShowEmoji: PropTypes.bool,
  defaultButtonLabel: PropTypes.string,
  placeholder: PropTypes.string,
  CustomButton: PropTypes.elementType,
};
