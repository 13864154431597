import PropTypes from "prop-types";
import { Loader } from "@circle-react-uikit/Loader";
import { CursorPagination } from "./CursorPagination";

export const TwoWayPagination = ({
  children,
  shouldShowPrevLoader = true,
  shouldShowNextLoader = true,
  hasNextPage,
  hasPrevPage,
  onPrevPage,
  onNextPage,
  firstItemId,
  lastItemId,
  componentId,
  isLoading,
}) => (
  <div id={componentId} className="overflow-auto pt-0">
    {isLoading
      ? shouldShowPrevLoader && <Loader center />
      : hasPrevPage && (
          <CursorPagination
            componentId={componentId}
            fetchNewPage={onPrevPage}
            onEvent="scrollUp"
            messageId={firstItemId}
            isLoading={isLoading}
          />
        )}
    {children}
    {isLoading
      ? shouldShowNextLoader && <Loader center />
      : hasNextPage && (
          <CursorPagination
            componentId={componentId}
            fetchNewPage={onNextPage}
            messageId={lastItemId}
            footerDistanceFromBottom={-500}
            isLoading={isLoading}
          />
        )}
  </div>
);

TwoWayPagination.propTypes = {
  shouldShowPrevLoader: PropTypes.bool,
  shouldShowNextLoader: PropTypes.bool,
  hasNextPage: PropTypes.bool.isRequired,
  hasPrevPage: PropTypes.bool.isRequired,
  onPrevPage: PropTypes.func.isRequired,
  onNextPage: PropTypes.func.isRequired,
  firstItemId: PropTypes.any.isRequired,
  lastItemId: PropTypes.any.isRequired,
  children: PropTypes.node,
  componentId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};
