import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { Tippy } from "@circle-react-shared/Tippy";
import { Button } from "@circle-react-uikit/Button";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { useCommunityMemberPickerContext } from "../CommunityMemberPickerContext";

export const Summary = ({
  buttonLabel,
  countLabel,
  tooltipText,
  onClick,
  ...passThroughProps
}) => {
  const { selectionState = {} } = useCommunityMemberPickerContext();
  const { memberCount: count = 0, isLoading } = selectionState;
  const label = `${count} ${
    countLabel || t("community_member_select.count_label")
  }`;
  const defaultButtonLabel = count > 0 ? t("manage") : t("add");

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div
      className="flex w-full items-center justify-between"
      {...passThroughProps}
    >
      <div>
        <Typography.LabelSm>{label}</Typography.LabelSm>
        {tooltipText && (
          <Tippy content={tooltipText}>
            <Icon type="question-mark" className="icon icon--xs ml-2" />
          </Tippy>
        )}
      </div>
      <Button
        type="button"
        variant="secondary"
        onClick={onClick}
        className="w-24"
      >
        {buttonLabel || defaultButtonLabel}
      </Button>
    </div>
  );
};

Summary.propTypes = {
  buttonLabel: PropTypes.string,
  countLabel: PropTypes.string,
  tooltipText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
