import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";

export const NoResultsListItem = () => (
  <div className="mt-4 px-5 py-2">
    <Typography.LabelSm color="text-light">
      {t("no_matches_found")}
    </Typography.LabelSm>
  </div>
);

NoResultsListItem.propTypes = {};
