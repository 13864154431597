import { t } from "i18n-js";
import { useThemeContext } from "@/react/providers";
import { EmojiRenderer } from "@circle-react-shared/EmojiRenderer";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";
import { MemberCount } from "../../MemberCount";
import type { Record } from "../../interfaces";

export interface MemberTagRecordProps {
  record: Record;
  onClick?: (record: Record) => void;
}

export const MemberTagRecord = ({ record, onClick }: MemberTagRecordProps) => {
  const { currentAppearance } = useThemeContext();
  const {
    name,
    emoji,
    custom_emoji_url,
    custom_emoji_dark_url,
    tagged_members_count: count,
  } = record;
  const hasEmoji = emoji || custom_emoji_url || custom_emoji_dark_url;

  const handleClick = () => {
    onClick?.(record);
  };

  return (
    <button
      type="button"
      className="group flex w-full cursor-pointer items-center gap-3 p-2 text-left"
      onClick={handleClick}
    >
      <span>
        <EmojiRenderer
          className="!h-5 !w-auto"
          emoji={emoji}
          customEmojiUrl={custom_emoji_url}
          customEmojiDarkUrl={custom_emoji_dark_url}
          appearance={currentAppearance}
        />
      </span>
      {!hasEmoji && <Icon size={16} type="hash-v2" />}
      <Typography.LabelSm>{name}</Typography.LabelSm>
      <div className="min-w-[6rem] grow">
        <MemberCount count={count} />
      </div>
      <div className="text-dark invisible group-hover:visible">
        <Typography.LabelSm>{t("remove")}</Typography.LabelSm>
      </div>
    </button>
  );
};
