import classnames from "classnames";
import { t } from "i18n-js";
import invariant from "tiny-invariant";
import { useAdminAudienceEditModal } from "@circle-react/hooks/useAdminAudienceEditModal";
import { TruncateText } from "@circle-react-shared/uikit/TruncateText";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { CustomerColumnImage } from "./CustomerColumnImage";

export interface CustomerColumProp {
  customer: {
    id: number;
    avatar_url?: string;
    email?: string;
    name?: string;
    deleted_at?: string;
    public_uid?: string;
  };
  onRemove?: () => void;
  setSearchQuery?: (value: string) => void;
  shouldOpenAdminMemberEdit?: boolean;
  infoClassName?: string;
}

export const CustomerColumn = ({
  customer,
  onRemove,
  setSearchQuery,
  shouldOpenAdminMemberEdit,
  infoClassName,
}: CustomerColumProp) => {
  const adminMemberEdit = useAdminAudienceEditModal();
  const deactivatedUserString = t(
    "settings.paywalls_admin_dashboard.shared.deactivated_member",
  );
  const customerName = customer.name ? customer.name : deactivatedUserString;
  const memberDeletedString = customer.deleted_at ? deactivatedUserString : "";

  const shouldShowAdminMemberEdit =
    !!customer.public_uid && shouldOpenAdminMemberEdit;
  const showAdminMemberEdit = () => {
    if (shouldShowAdminMemberEdit) {
      invariant(customer.public_uid, "public_uid is required");
      adminMemberEdit({ publicId: customer.public_uid });
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/prefer-tag-over-role -- We have another <button> inside the children
    <div
      className={classnames(
        "group inline-flex items-center gap-4",
        "focus-visible:ring-secondary focus-visible:rounded-md focus-visible:ring-2 focus-visible:ring-offset-2",
        {
          "cursor-pointer": !!shouldShowAdminMemberEdit,
        },
      )}
      onClick={showAdminMemberEdit}
      onKeyPress={showAdminMemberEdit}
      tabIndex={0}
      role="button"
    >
      <CustomerColumnImage
        avatar_url={customer.avatar_url}
        name={customerName}
      />
      <div className={classnames("grid pr-4", infoClassName)}>
        <TruncateText>
          <Typography.LabelSm weight="semibold">{`${customerName}${memberDeletedString}`}</Typography.LabelSm>
        </TruncateText>

        <TruncateText>
          <Typography.LabelSm color="text-default">
            {customer.email}
          </Typography.LabelSm>
        </TruncateText>
      </div>
      {onRemove && (
        <Typography.LabelSm weight="medium">
          <div className="invisible group-hover:visible">
            <Button
              type="button"
              variant="plain"
              className="text-v2-danger"
              onClick={() => {
                setSearchQuery && setSearchQuery("");
                onRemove();
              }}
            >
              {t("remove")}
            </Button>
          </div>
        </Typography.LabelSm>
      )}
    </div>
  );
};
