import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { useEditorApi } from "@circle-react-shared/TrixEditor/TrixEditorContext";
import { useEditorMode } from "@circle-react-shared/TrixEditor/useEditorMode";
import { EmojiPickerV3 } from "@circle-react-shared/uikit/EmojiPickerV3";
import type { EmojiData } from "@circle-react-shared/uikit/EmojiPickerV3";

export const EmojiPicker = () => {
  const { trixApiWrapper, editorElement } = useEditorApi();
  const { resetEditorMode, setModeEmojiPicker } = useEditorMode();

  const onEmojiSelect = (emojiData: EmojiData) => {
    setModeEmojiPicker();
    trixApiWrapper.recordUndoEntry("Insert emoji");
    trixApiWrapper.insertString(emojiData.emoji);
    resetEditorMode();
    editorElement.setSelectedRange(-1);
  };

  return (
    <EmojiPickerV3
      onEmojiSelect={onEmojiSelect}
      target={
        <button
          type="button"
          className="toolbar__btn toolbar__btn--emoji"
          aria-label={t("trix_editor.toolbar.emojis")}
        >
          <Icon type="emoji" />
        </button>
      }
    />
  );
};
