import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useKeyboardNavigation } from "@circle-react/hooks/utils/useKeyboardNavigation";
import { useCommunityMemberPickerContext } from "../CommunityMemberPickerContext";
import { RecordTypeLabel } from "../RecordTypeLabel";
import { mapSearchResultsRecords } from "../helpers";
import { CommunityMemberResult } from "./CommunityMemberResult";
import { MemberTagResult } from "./MemberTagResult";
import { SpaceGroupResult } from "./SpaceGroupResult";
import { SpaceResult } from "./SpaceResult";

export const SearchResults = ({
  records = [],
  onSelect,
  onReset,
  selectionModalRef,
  includeAllResultTypes = false,
}) => {
  const { selectionState = {} } = useCommunityMemberPickerContext();
  const {
    spaceGroups,
    spaces,
    memberTags,
    communityMembers,
    combinedRecords,
    recordCount,
  } = mapSearchResultsRecords(records, includeAllResultTypes, selectionState);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const next = useCallback(
    () =>
      setSelectedIndex(
        selectedIndex !== null
          ? Math.min(selectedIndex + 1, recordCount - 1)
          : 0,
      ),
    [selectedIndex, recordCount],
  );
  const previous = useCallback(
    () => setSelectedIndex(Math.max(selectedIndex - 1, 0)),
    [selectedIndex],
  );
  const onArrowDown = useCallback(
    event => {
      if (recordCount) {
        event.preventDefault();
      }
      next();
    },
    [next, recordCount],
  );
  const onArrowUp = useCallback(
    event => {
      if (recordCount) {
        event.preventDefault();
      }
      previous();
    },
    [previous, recordCount],
  );
  const onEnter = useCallback(
    event => {
      event.stopPropagation();
      event.preventDefault();
      // NOTE: have to check for null or undefined since 0 is valid
      if (selectedIndex === null || typeof selectedIndex === "undefined") {
        return;
      }
      onSelect(combinedRecords[selectedIndex]);
    },
    [onSelect, combinedRecords, selectedIndex],
  );
  const onEscape = useCallback(
    event => {
      if (recordCount) {
        event.stopPropagation();
      }
      setSelectedIndex(null);
      onReset();
    },
    [onReset, recordCount],
  );
  useKeyboardNavigation({
    onArrowDown,
    onArrowUp,
    onEnter,
    onEscape,
    enabled: !!recordCount,
    elementRef: selectionModalRef,
  });

  if (!recordCount) {
    return null;
  }

  return (
    <div className="border-primary bg-primary absolute top-full z-50 mt-1 max-h-72 w-full overflow-auto rounded-lg border shadow-md">
      {includeAllResultTypes && !!spaceGroups.length && (
        <RecordTypeLabel>
          {t("community_member_select.record_type_labels.space_groups")}
        </RecordTypeLabel>
      )}
      {spaceGroups.map(record => (
        <SpaceGroupResult
          key={record.id}
          record={record}
          onClick={onSelect}
          isSelected={record?.index === selectedIndex}
        />
      ))}
      {includeAllResultTypes && !!spaces.length && (
        <RecordTypeLabel>
          {t("community_member_select.record_type_labels.spaces")}
        </RecordTypeLabel>
      )}
      {spaces.map(record => (
        <SpaceResult
          key={record.id}
          record={record}
          onClick={onSelect}
          isSelected={record?.index === selectedIndex}
        />
      ))}
      {includeAllResultTypes && !!memberTags.length && (
        <RecordTypeLabel>
          {t("community_member_select.record_type_labels.member_tags")}
        </RecordTypeLabel>
      )}
      {memberTags.map(record => (
        <MemberTagResult
          key={record.id}
          record={record}
          onClick={onSelect}
          isSelected={record?.index === selectedIndex}
        />
      ))}
      {includeAllResultTypes && !!communityMembers.length && (
        <RecordTypeLabel>
          {t("community_member_select.record_type_labels.members")}
        </RecordTypeLabel>
      )}
      {communityMembers.map(record => (
        <CommunityMemberResult
          key={record.id}
          record={record}
          onClick={onSelect}
          isSelected={record?.index === selectedIndex}
        />
      ))}
    </div>
  );
};

SearchResults.propTypes = {
  records: PropTypes.array,
  onSelect: PropTypes.func,
  onReset: PropTypes.func,
  selectionModalRef: PropTypes.any,
  includeAllResultTypes: PropTypes.bool,
};
