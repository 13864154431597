import PropTypes from "prop-types";
import { t } from "i18n-js";
import { PaywallCurrencyAmountFormInput } from "@circle-react/components/Paywalls";
import { Form } from "@circle-react-uikit/Form";
import { formId, localeStringRoot } from "./constants";
import { useRefundChargeForm } from "./hooks/useRefundChargeForm";

export const RefundChargeForm = ({ charge }) => {
  const { transformValueIntoCurrency, minAmountToRefund, maxAmountToRefund } =
    useRefundChargeForm({ charge });

  const currency = charge.currency;

  return (
    <div className="flex flex-col space-y-2 pt-2">
      <Form.Item
        className="border-0"
        labelClassName="text-sm font-semibold"
        name="amount"
        translationRoot={`${localeStringRoot}.refund_form`}
        hidePlaceholder
        hideDescription
        hiddenField
        id={formId}
        transformValue={transformValueIntoCurrency}
        rules={{
          required: true,
          validate: {
            minAmountToRefund,
            maxAmountToRefund,
          },
        }}
      >
        <PaywallCurrencyAmountFormInput
          currency={currency}
          className="form-control mr-px"
        />
      </Form.Item>
      <Form.Item
        className="border-0"
        labelClassName="text-sm font-semibold"
        name="reason_details"
        translationRoot={`${localeStringRoot}.refund_form`}
        hideDescription
        hiddenField
        id={formId}
        rules={{
          required: t(
            `${localeStringRoot}.refund_form.alerts.reason_details_required`,
          ),
          maxLength: {
            value: 255,
            message: t(
              `${localeStringRoot}.refund_form.alerts.reason_details_max_length`,
            ),
          },
        }}
      >
        <Form.Input name="reason_details" variant="textarea" />
      </Form.Item>
    </div>
  );
};

RefundChargeForm.propTypes = {
  charge: PropTypes.object.isRequired,
};
