/* eslint-disable no-restricted-syntax -- Not translatable strings */
import { Portal } from "@material-ui/core";
import type { Editor } from "@tiptap/react";
import classNames from "classnames";
import { t } from "i18n-js";
import { InsertVariableButton } from "@circle-react-uikit/InsertVariableButton";

const EVENT_GROUP = t("dynamic_vars_groups.event");
const VARIABLES = [
  {
    group: t("dynamic_vars_groups.community"),
    label: "{{community.name}}",
    value: "{{community.name}}",
  },
  {
    group: EVENT_GROUP,
    label: "{{event.name}}",
    value: "{{event.name}}",
  },
  {
    group: EVENT_GROUP,
    label: "{{event.date}}",
    value: "{{event.date}}",
  },
  {
    group: EVENT_GROUP,
    label: "{{event.time}}",
    value: "{{event.time}}",
  },
  {
    group: EVENT_GROUP,
    label: "{{event.url}}",
    value: "{{event.url}}",
  },
  {
    group: EVENT_GROUP,
    label: "{{event.location}}",
    value: "{{event.location}}",
  },
];
interface InsertVariablesMenuProps {
  editor: Editor | null;
  isEditorFocused: boolean;
  portalId: string;
}

export function InsertVariablesMenu({
  editor,
  isEditorFocused,
  portalId,
}: InsertVariablesMenuProps) {
  if (!editor) {
    return null;
  }

  const menuIconClass = classNames(
    isEditorFocused
      ? "text-default dark:text-dark"
      : "text-lightest dark:text-default",
  );

  return (
    <Portal container={() => document.getElementById(portalId)}>
      <InsertVariableButton
        wrapperClassName="w-80 pt-3"
        optionsClassName="bottom-8"
        className={menuIconClass}
        variables={VARIABLES}
        editor={editor}
        tooltipPlacement="top"
      />
    </Portal>
  );
}
